<template>
    <transition name="slide-down">
        <header v-show="showHeader" ref="$headerElm" class="layout-header">
            <ClientOnly>
                <HeaderNotifications
                    @notifications="handleNotificationStatus"
                    class="layout-header__notification"
                />
            </ClientOnly>

            <HeaderAdBar v-if="adBar" :ad-bar="adBar" class="layout-header__adbars" />

            <div
                class="layout-header__place"
                :class="$config.public.variant === 'bchollos' ? 'bg-site-secondary' : 'bg-site-primary'"
            >
                <div
                    class="layout-header__container"
                    :class="$config.public.variant === 'megadescuentos' ? 'gap-x-2 md:gap-x-0' : ''"
                >
                    <h1
                        v-if="$route.path === '/'"
                        class="order-3 flex-grow lg:order-1 lg:flex-none"
                        :class="{
                            'flex-grow lg:flex-none': $config.public.variant !== 'megadescuentos',
                        }"
                    >
                        <NuxtLink no-prefetch to="/" rel="follow" class="layout-header__logo">
                            <picture
                                :class="
                                    $config.public.variant === 'megadescuentos'
                                        ? 'mx-auto w-24 lg:w-52'
                                        : 'w-52'
                                "
                            >
                                <source
                                    v-if="$config.public.variant === 'megadescuentos'"
                                    media="(min-width: 1024px)"
                                    :srcset="$assets.brand.headerLogo"
                                />
                                <img
                                    :src="
                                        $config.public.variant === 'megadescuentos'
                                            ? $assets.brand.headerLogoLetter
                                            : $assets.brand.headerLogo
                                    "
                                    :alt="$lang.brand"
                                    :title="$lang.brand"
                                />
                            </picture>
                        </NuxtLink>
                    </h1>
                    <div
                        v-else
                        class="order-3 flex-grow lg:order-1 lg:flex-none"
                        :class="{ 'flex-grow lg:flex-none': $config.public.variant !== 'megadescuentos' }"
                    >
                        <NuxtLink no-prefetch to="/" rel="follow" class="layout-header__logo">
                            <picture
                                :class="
                                    $config.public.variant === 'megadescuentos'
                                        ? 'mx-auto w-24 lg:w-52'
                                        : 'w-52'
                                "
                            >
                                <source
                                    v-if="$config.public.variant === 'megadescuentos'"
                                    media="(min-width: 1024px)"
                                    :srcset="$assets.brand.headerLogo"
                                />
                                <img
                                    :src="
                                        $config.public.variant === 'megadescuentos'
                                            ? $assets.brand.headerLogoLetter
                                            : $assets.brand.headerLogo
                                    "
                                    :alt="$lang.brand"
                                    :title="$lang.brand"
                                />
                            </picture>
                        </NuxtLink>
                    </div>

                    <HeaderMenuNew class="layout-header__menu" :admin-links="menuLink" />

                    <div
                        class="layout-header__search"
                        :class="
                            $config.public.variant === 'megadescuentos' ? 'layout-header__search--order' : ''
                        "
                    >
                        <HeaderSearch
                            :on-open-handler="toggleUserPanel"
                            :default-categories="searchSuggestions.categories"
                            :default-stores="searchSuggestions.stores"
                            :default-events="searchSuggestions.events"
                        />
                    </div>

                    <HeaderUser
                        v-if="$config.public.variant === 'megadescuentos'"
                        v-show="showUserPanel"
                        class="layout-header__user-panel"
                    />
                    <!-- v-show="showUserPanel" -->
                </div>
            </div>
            <HeaderHeadband :headband="headband" class="layout-header__headband" />
        </header>
    </transition>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'

const RootStore = useRootStore()

const { $assets } = useNuxtApp()

const mounted = ref(false)

const showUserPanel = ref(true)

const showHeader = ref(true)

const preventScrollListener = ref(false)

const listener = ref(() => null) as Ref<(e?: any) => any>

const adBar = RootStore.layoutData?.data.header.adbar || false

const headband = RootStore.headbandData?.page

const menuLink = RootStore.layoutData?.data.header.links

const searchSuggestions = RootStore.layoutData?.data.header.search || {
    categories: [],
    stores: [],
    events: [],
}

const $headerElm = ref()

useHead({
    link: [
        {
            as: 'image',
            type: 'image/png',
            rel: 'preload',
            href: $assets.brand.headerLogo,
        },
        {
            rel: 'preload',
            as: 'image',
            type: 'image/png',
            href: $assets.brand.headerLogoLetter,
        },
    ],
})

onMounted(async () => {
    setTimeout(() => {
        mounted.value = true
    }, 3000)

    await nextTick()

    let lastScrollY = window.scrollY

    listener.value = () => {
        if (!preventScrollListener.value) {
            if (!$headerElm.value) return

            showHeader.value =
                lastScrollY > scrollY || ($headerElm.value as HTMLElement).clientHeight * 1.5 > scrollY

            lastScrollY = window.scrollY
        }
    }

    document.addEventListener('scroll', listener.value)
})

onUnmounted(() => {
    document.removeEventListener('scroll', listener.value)
})

const handleNotificationStatus = (notification: boolean) => {
    if (notification) {
        preventScrollListener.value = true
        showHeader.value = true
    } else {
        preventScrollListener.value = false
    }
}

const toggleUserPanel = (show: boolean) => {
    showUserPanel.value = show
}
</script>

<style lang="postcss" scoped>
.layout-header {
    @apply fixed top-0 z-[60] w-full shadow-md transition-all duration-300;

    height: var(--total-header-height, 0rem);

    &__notification {
        @apply absolute w-full transition-[bottom] duration-300;
        bottom: calc(
            var(--layout-header-adbar-height, 0rem) + var(--header-menu-height, 0rem) +
                var(--headband-height, 0rem)
        );
    }

    &__adbars {
        @apply absolute z-10 w-full transition-[bottom] duration-300;
        bottom: calc(var(--header-menu-height, 0rem) + var(--headband-height, 0rem));
    }

    &__place {
        @apply absolute z-50 w-full;
        bottom: var(--headband-height, 0rem);
    }

    &__headband {
        @apply absolute bottom-0 z-0;
    }

    &__container {
        @apply container flex h-14 items-center px-0 xs:px-2 lg:h-16;
    }
    &__logo {
        @apply mr-2.5 block;
        picture {
            @apply block;
            img {
                @apply h-full w-full object-contain;
            }
        }
    }
    &__menu {
        @apply order-1 ml-0 flex-none lg:order-2 lg:ml-4 lg:flex-grow;
    }
    &__search {
        @apply order-3 mr-2.5 flex-grow;
        &--order {
            @apply order-2 flex-grow-0 md:order-3 md:mr-2.5 md:flex-grow;
        }
    }
    &__user-panel {
        @apply order-4 flex-none;
    }
}
</style>
